@import './vars.scss';
@import './theme.scss';

.paper {
    padding: 0;
}

.account_drawer {
    width: 100vw;
    background-color: var(--background-2);
    position: relative;
    @include flex(center, space-between);
    flex-direction: column;
    height: 100vh;
    .top_drawer{
        width: 100%;
        @include flex(center,start);
        flex-direction: column;
        .drawer_header {
            width: calc(100% - 32px);
            border-bottom: 1px solid var(--border-main);
            padding: 16px;
            color: var(--font-mine);
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.28px;
            @include flex(center, space-between);
            .logo{
                @include flex(center, start);
                gap: 12px;
                color: var(--font-mine, #FCFCFC);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                img{
                    width: 35px;
                    height: 36px;
                }
            }            
            .close_icon{
                @include flex(center, center);
                padding: 12px;
                border-radius: 9999px;
                background: var(--buttons-alternative-default);
                path{
                    stroke: var(--font-mine);
                }
            }
        }
        .drawer_body {
            width: calc(100% - 32px);
            padding: 16px;
            @include flex(center, start);
            flex-direction: column;
            gap: 16px;
            .balances{
                width: 100%;
                @include flex(center,center);
                gap: 16px;
                flex-wrap: wrap;
                border: 1px solid var(--border-main, #26272B);
                border-radius: 16px;
                padding: 0.8vh;
                .account_id_balance{
                    width: 100%;
                    @include flex(stretch,center);
                    padding: 1.6vh 0.8vh;
                    border-radius: 8px;
                    background: var(--buttons-alternative-switcher, #1D1D20);
                    .inside_balance{
                        flex: 0.99;
                        text-align: center;
                        align-items: center;
                    }
                }
                .margin_free_profit{
                    width: 100%;
                    @include flex(center,center);
                    padding: 1.6vh 0.8vh;
                }
                .inside_balance{
                    @include flex(start,center);
                    flex-direction: column;
                    padding-left: 2.4vh;
                    padding-right: 2.4vh;
                    p{
                        margin: 0;
                        color: var(--font-mine, #1F2937);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                    }
                    span{
                        color: var(--font-sub-font, #9CA3AF);
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.24px;
                    }
                    .profit{
                        color: var(--Other-Green, #00B4A0);
                    }
                }
            }
            .changePass{
                width: 100%;
                @include flex(start,start);
                flex-direction: column;
                gap: 8px;
                label{
                    
                    color: var(--font-sub-font, #9CA3AF);
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.24px;
                }
                .input{
                    width: 100%;
                    @include flex(center,start);
                    gap: 12px;
                    input{
                        border-radius: var(--corner-full, 1000px);
                        border: 1px solid var(--border-main, #26272B);
                        background: var(--input-bg, #131316);
                        color: var(--font-mine);
                        padding: 16px;
                        font-family: $font;
                        width: 100%;
                    }
                    button{
                        border: none;
                        color: #FCFCFC;
                        padding: 16px;
                        outline: none;
                        font-family: $font;
                        cursor: pointer;
                        border-radius: 9999px;
                        background: var(--Other-Purple, #A855F7);
                    }
                }
            }
            .tradingTerm{
                width: calc(100% - 2.4vh);
                @include flex(center,center);
                gap: 12px;
                border-radius: 9999px;
                background: var(--other-purple);
                padding: 1.6vh 1.2vh;   
                color: var(--global-dark-25);
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh; 
                cursor: pointer;
                text-decoration: none;
                transition: all 0.15s ease-in-out;
            }
            
            .links{
                margin-top: 2.4vh;
                width: 100%;
                @include flex(center,start);
                flex-direction: column;
                gap: 0.8vh;

                label{
                    margin-bottom: 0.8vh;
                    width: 100%;
                    text-align: left;
                    color: var(--font-sub-font);
                    font-size: 1.4vh;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
                .button{
                    width: calc(100% - 2.4vh);
                    @include flex(center,start);
                    gap: 1.2vh;
                    padding: 1.2vh;
                    color: var(--nav-button-font-default);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    text-decoration: none;
                    transition: all 0.15s ease-in-out;
                    path{
                        fill: var(--nav-button-font-default);
                    }
                    ellipse{
                        fill: var(--nav-button-font-default);
                    }
                    &.active{
                        border-radius: 9999px;
                        background: var(--nav-button-active);
                        color: var(--nav-button-font-active);
                        path{
                            fill: var(--nav-button-font-active);
                        }
                        ellipse{
                            fill: var(--nav-button-font-default);
                        }
                    }
                }
                .button:hover{
                    border-radius: 9999px;
                    background: var(--nav-button-hover);
                }
            }
            .settings{
                margin-top: 12px;
                width: 100%;
                @include flex(center,start);
                gap: 12px;
            .theme{
                @include flex(center,center);
                cursor: pointer;
                .moon{
                    padding: 1vh;
                    @include flex(center,center);
                    path{
                        fill: var(--font-sub-font);
                    }
                    &.active{
                        path{
                            fill:  var(--font-mine);
                        }
                    }
                }
                .sun{
                    padding: 1vh;
                    @include flex(center,center);
                    path{
                        fill: var(--font-sub-font);
                    }
                    &.active{
                        path{
                            fill:  var(--font-mine);
                        }
                    }
                }
            }
            .lang_currency{
                @include flex(center,center);
                padding: 1.2vh;
                gap: 1.2vh;
                border-radius: 9999px;
                background: var(--buttons-alternative-default);
                .lang{
                    @include flex(center,center);
                    cursor: pointer;
                    path{
                        stroke: var(--font-mine)
                    }
                }
                .border{
                    @include flex(center,center);
                    path{
                        stroke: var(--border-main);
                    }
                }
                .currency{
                    @include flex(center,center);
                    cursor: pointer;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }
            }
        }
    }
    .drawer_footer{
        width: calc(100% - 3.2vh);
        padding: 1.6vh;
        @include flex(center,center);
        gap: 0.8vh;
        border-top: 1px solid var(--border-main);
        div{
            flex: 1;
            @include flex(center,center);
            border-radius: 9999px;
            background: var(--buttons-alternative-default);
            color: var(--nav-button-font-active);
            font-size: 14px;
            padding: 16px;
            font-weight: 500;
            line-height: 1.6vh;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }
        div:hover{
            background: var(--buttons-alternative-hover);
        }
    }
}