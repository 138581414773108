@import './theme.scss';
@import './vars.scss';

.topbar{
    width: 100%;
    height: 5.7vh;
    border-radius: 9999px;
    border: 1px solid var(--border-main, #26272B);
    background: var(--background-2, #18181B);
    @include flex(center,space-between);
    
    .mobile_navbar{
        display: none;
    }
    .logo{
        padding: 0.8vh;
        @include flex(center,start);
        gap: 1.3vh;
        img{
            width: 4.2vh;
            height: 4.2vh;
        }
        h2{
            color: var(--font-mine, #1F2937);
            font-size: 1.8vh;
            font-weight: 500;
            line-height: 2.4vh;
        }
        .addition_balance{
            @include flex(center,center);
            border-right: 1px solid var(--border-main, #E5E7EB);
            border-left: 1px solid var(--border-main, #E5E7EB);
            padding: 1.6vh;
            padding-top: 0.8vh;
            padding-bottom: 0.8vh;
            gap: 2.4vh;
            p{
                margin: 0;
                color: var(--font-mine, #1F2937);
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh;
            }
            span{
                color: var(--font-sub-font, #9CA3AF);
                font-size: 1.2vh;
                font-weight: 500;
                line-height: 1.6vh;
                letter-spacing: 0.24px;
            }
        }
    }
    .right_side{
        width: 129.8vh;
        @include flex(stretch,end);
        gap: 2.4vh;
        .balance{
            border-right: 1px solid var(--border-main, #E5E7EB);
            border-left: 1px solid var(--border-main, #E5E7EB);
            padding: 0.8vh;
            @include flex(center,center);
            .inside_balance{
                @include flex(start,center);
                flex-direction: column;
                padding-left: 2.4vh;
                padding-right: 2.4vh;
                p{
                    margin: 0;
                    color: var(--font-mine, #1F2937);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
                span{
                    color: var(--font-sub-font, #9CA3AF);
                    font-size: 1.2vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                    letter-spacing: 0.24px;
                }
                .profit{
                    color: var(--Other-Green, #00B4A0);
                }
            }
        }
        .buttons{
            @include flex(center,end);
            gap: 0.8vh;
            padding: 0.8vh;
            .theme{
                @include flex(center,center);
                cursor: pointer;
                .moon{
                    padding: 1vh;
                    @include flex(center,center);
                    path{
                        fill: var(--font-sub-font);
                    }
                    &.active{
                        path{
                            fill:  var(--font-mine);
                        }
                    }
                }
                .sun{
                    padding: 1vh;
                    @include flex(center,center);
                    path{
                        fill: var(--font-sub-font);
                    }
                    &.active{
                        path{
                            fill:  var(--font-mine);
                        }
                    }
                }
            }
            .lang_currency{
                @include flex(center,center);
                padding: 1.2vh;
                gap: 1.2vh;
                border-radius: 9999px;
                background: var(--buttons-alternative-default);
                .lang{
                    @include flex(center,center);
                    cursor: pointer;
                    path{
                        stroke: var(--font-mine)
                    }
                }
                .border{
                    @include flex(center,center);
                    path{
                        stroke: var(--border-main);
                    }
                }
                .currency{
                    @include flex(center,center);
                    cursor: pointer;
                    color: var(--nav-button-font-active);
                    font-size: 1.4vh;
                    font-weight: 500;
                    line-height: 1.6vh;
                }
            }
            .to_cabinet{
                padding: 1.2vh;
                text-decoration: none;
                border-radius: 9999px;
                background: var(--Other-Purple, #A855F7);
                color:  #FCFCFC;
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh;
                @include flex(center,center);
                gap: 8px;
            }
            .logout{
                @include flex(center,start);
                border-radius: 9999px;
                padding: 0.5vh 1.6vh 0.5vh 0.8vh;
                background: var(--buttons-alternative-hover);
                cursor: pointer;
                gap: 1.2vh;
                transition: all 0.15s ease-in-out;
                color: var(--nav-button-font-active, #FCFCFC);
                font-size: 1.4vh;
                font-weight: 500;
                line-height: 1.6vh;
                path{
                    stroke: var(--font-mine);
                }
            }
            .logout:hover{
                border-radius: 9999px;
                background: var(--buttons-alternative-default);
            }
        }
    }
}
@media (max-width: 600px){
    .topbar{
        background: none;
        border: none;
        width: 100%;
        .mobile_navbar{
            @include flex(center,center);
            width: 4vh;
            height: 4vh;
            border-radius: 9999px;
            background: var(--buttons-alternative-default);
            path{
                stroke: var(--font-mine);
            }
        }
        .logo{
            display: none;
        }
        .right_side{
            width: max-content;
            justify-content: end;
            .balance{
                display: none;
            }
            .buttons{
                padding: 0;
              .theme{
                display: none;
              }  
              .lang_currency{
                display: none;
              }
            }
        }
    }
}